import React, { useState } from 'react'
import Quantify from '../../assets/quantify_cs.svg'
import Ownership from '../../assets/ownership_cs.svg'
import DataControl from '../../assets/data_control.svg'
import Strategy2019 from '../../assets/strategies_2019.svg'
import RoiCs from '../../assets/roi_cs.svg'
import Twitter from '../../assets/vector/Vector-1.svg'
import Linkedln from '../../assets/vector/Vector-2.svg'
import Youtube from '../../assets/vector/Vector-3.svg'
import GrowBusiness from '../../assets/GrowBusines.png'
import Phone from '../../assets/icon_phone.svg'
import Email from '../../assets/icon_mail.svg'

const Footer = () => {

  return (
    <>
      <div className='flex sm:items-center sm:justify-center md:px-[10%] px-[2%]'>
        <div className='grid  grid-cols-1 sm:grid-cols-3 lg:grid-cols-5  sm:gap-10 lg:gap-16 gap-4 '>
          <div>
            <h2 className='text-textGrayColor leading-[40px] '>Company</h2>
            <div>
              <ul className='text-dimGray text-[14px] leading-[30px]'>
                <li>About Us</li>
                <li>Career</li>
                <li>Contact Us</li>
              </ul>
            </div>
          </div>
          <div>
            <h2 className='text-textGrayColor leading-[30px]'>Personas</h2>
            <div>
              <ul className='text-dimGray text-[14px] leading-[30px]'>
                <li>Customer Success Manager</li>
                <li>Executives – CXOs & VPs</li>
                <li>Customer Success Operations</li>
              </ul>
            </div>
          </div>
          <div>
            <h2 className='text-textGrayColor leading-[40px]'>Product</h2>
            <div>
              <ul className='text-dimGray text-[14px] leading-[30px]'>
                <li>Data Integration</li>
                <li>UI Builder</li>
                <li>Triggered Alerts</li>
                <li>External Process Automation</li>
                <li>Playbooks</li>
              </ul>
            </div>
          </div>
          <div>
            <h2 className='text-textGrayColor leading-[40px]'>Solutions</h2>
            <div>
              <ul className='text-dimGray text-[14px] leading-[30px]'>
                <li>Customer 360</li>
                <li>Customer Onboarding</li>
                <li>Renewal Management</li>
                <li>Tech Touch Customer Success</li>
                <li>Success Planning</li>
              </ul>
            </div>
          </div>
          <div>
            <h2 className='text-textGrayColor leading-[40px]'>Contact Info</h2>
            <div>
              <ul className='text-dimGray text-[14px] leading-[30px]'>
                <li>1525 McCarthy Blvd, Milpitas, CA 95035</li>
                <li className='flex gap-2'> <img src={Phone} /> +1-415-938-6456</li>
                <li className='flex gap-2'> <img src={Email} /> sales@success4.us</li>
                <li className='pt-2 flex flex-row-3 gap-2'>
                  <a href='#'>
                    <img src={Twitter} />
                  </a>
                  <a href='#'>
                    <img src={Linkedln} />
                  </a>
                  <a href='#'>
                    <img src={Youtube} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className='px-[10%] pt-[3vh]'>
        <form role='search' method='get' className='flex gap-2' >
          <label>
            <span className='hidden'>Search For: </span>
            <input type='search' className='border border-gainsBoro p-4 lg:w-[25vw] w-[50vw]' placeholder='Search...' name='search' />
          </label>
          <button type='submit' className='p-2 sm:w-[10vw] w-[15vw] text-white bg-spanishCarmine md:text-xl text-md font-medium text-center' >Search</button>
        </form>
        <p className='text-grayColor'>Recent Post</p>
      </div>

      <div className='py-[3%] md:px-[10%] px-[2%]'>
        <div>
          <ul className='grid md:grid-cols-2'>
            <li className='mb-[10px] flex gap-5'>
              <a href='/grow-your-business-by-growing-customer-numbers'>
                <img height='87' width='87' src={GrowBusiness} />
              </a>
              <div>
                <span className='font-normal font-bold text-[16px] leading-[22px] text-blueBlack pt-[5px] '>Grow Your Business by Growing Customer Numbers</span>
                <div className='flex gap-2 text-textGrayColor uppercase text-[12px]'>
                  <div >By Prashant</div>
                  <div >Dec 02, 2020</div>
                </div>
              </div>
            </li>
            <li className='mb-[10px] flex gap-5'>
              <a href='/how-to-quantify-customer-success'>
                <img height='87' width='87' src={Quantify} />
              </a>
              <div>
                <span className='font-normal font-bold text-[16px] leading-[22px] text-blueBlack pt-[5px] '>How to Quantify Customer Success</span>
                <div className='flex gap-2 text-textGrayColor uppercase text-[12px]'>
                  <div >By Prashant</div>
                  <div >Dec 02, 2020</div>
                </div>
              </div>
            </li>
            <li className='mb-[10px] flex gap-5'>
              <a href='/who-should-own-customer-success'>
                <img height='87' width='87' src={Ownership} />
              </a>
              <div>
                <span className='font-normal font-bold text-[16px] leading-[22px] text-blueBlack pt-[5px] '>Who should own Customer Success</span>
                <div className='flex gap-2 text-textGrayColor uppercase text-[12px]'>
                  <div >By Balaji</div>
                  <div >Sep 10, 2020</div>
                </div>
              </div>
            </li>
            <li className='mb-[10px] flex gap-5'>
              <a href='/controlling-your-customer-success-data-in-the-cloud'>
                <img height='87' width='87' src={DataControl} />
              </a>
              <div>
                <span className='font-normal font-bold text-[16px] leading-[22px] text-blueBlack pt-[5px] '>Controlling Your Customer Success Data in the Cloud</span>
                <div className='flex gap-2 text-textGrayColor uppercase text-[12px]'>
                  <div >By Vivek</div>
                  <div >Jul 20, 2020</div>
                </div>
              </div>
            </li>
            <li className='mb-[10px] flex gap-5'>
              <a href='/getting-full-roi-from-your-customer-success-investment-3'>
                <img height='87' width='87' src={RoiCs} />
              </a>
              <div>
                <span className='font-normal font-bold text-[16px] leading-[22px] text-blueBlack pt-[5px] '>Getting Full ROI from your Customer Success Investment</span>
                <div className='flex gap-2 text-textGrayColor uppercase text-[12px]'>
                  <div >By Vivek</div>
                  <div >Jan 11, 2020</div>
                </div>
              </div>
            </li>
            <li className='mb-[10px] flex gap-5'>
              <a href='/4-effective-customer-success-strategies-for-2019'>
                <img height='87' width='87' src={Strategy2019} />
              </a>
              <div>
                <span className='font-normal font-bold text-[16px] leading-[22px] text-blueBlack pt-[5px] '>4 Effective Customer Success Strategies for 2019</span>
                <div className='flex gap-2 text-textGrayColor uppercase text-[12px]'>
                  <div >By Balaji</div>
                  <div >Aug 04, 2019</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className='md:px-[10%] py-[2vh]'>
        <hr />
        <div className='flex justify-between'>
          <div>
            <p className='text-grayColor text-sm p-2'>Copyright © 2024 Success4  Inc. All rights reserved</p>
          </div>
          <div className='flex gap-5 text-grayColor text-sm p-2'>
            <p>Privacy Policy</p>
            <p>Terms Of Service</p>
          </div>
        </div>
      </div>
    </>

  )
}

export default Footer