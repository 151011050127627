import React from 'react'
import GrowBusiness from '../../../../assets/GrowBusines.png'
import Quantify from '../../../../assets/quantify_cs.svg'
import Ownership from '../../../../assets/ownership_cs.svg'
import DataControl from '../../../../assets/data_control.svg'
import RoiCs from '../../../../assets/roi_cs.svg'
import Hand from '../../../../assets/hand.png'

export default function GrowPage() {

  return (
    <div className='w-full' >
      <div className='mb-[5vh]'>
        <div className='mb-[5vh]'>
          <div className='w-full absolute  '>
            <img className='w-full xl:h-[calc(100vh-15vh)] object-cover' src={GrowBusiness} />
          </div>
          <section className='relative flex flex-col items-center justify-end xl:h-[calc(100vh-15vh)]  bg-black bg-opacity-30 text-white'>
            <a href='/blog' className='pb-[2%] text-center'>Back to Blogs</a>
            <h1 className='text-4xl font-bold pb-[2%] text-center'>Grow Your Business by Growing Customer Numbers</h1>
            <p className='pb-[5%] text-center'>Prashant / February 17, 2021</p>
          </section>
        </div>
        <div className='w-full wrap text-[18px] '>
          <div className='flex flex-col items-center justify-center '>
            <div className='md:px-[20%] px-[5%]'>
              <div className=' wrap  leading-[27px] py-3 text-grayColor'>Business innovation is no longer contained to trade practices. It has also become a necessary aspect of customer success and customer retention. For a long while now, businesses have marked their success by various metrics, out of which their customer acquisition and retention parameters hold significant value.</div>
              <div className='wrap  leading-[27px] py-3 text-grayColor'>
                A business with a healthy customer base is a strong one, which is why businesses across the globe have special teams that focus on building and maintaining a constantly expanding customer base. But beyond this, businesses need to give special attention to acquiring new customers, which could prove to be easier if approached with the right tools.
              </div>
              <div className='leading-[27px] wrap text-grayColor  py-3'>
                There are certain steps that a business could take to grow customers in future.
              </div>
              <div>
                <h1 className='text-[30px] font-bold leading-[27px] '>Approach with a different strategy:</h1>
                <div className='leading-[27px] py-3 text-grayColor'>
                  The cardinal rule of any business strategy is that what worked once may not always work. The primary mistake any business might make is to think that the strategies that got them the customers during their inception would help with the same purpose, at the same rate, irrespective of the changes in the markets and scenarios.
                </div>
                <div className='leading-[27px] py-3 text-grayColor'>
                  But changing times mandate that businesses have to constantly rethink strategies and sometimes even be prepared to build the parachute during their free fall. Focus areas constantly keep shifting, and so do customer needs. So, the strategies that are aligned with the times would produce the best results.
                </div>
                <div className='leading-[27px] py-3 text-grayColor'>
                  Most importantly, the plans for customer retention and the expansion of the customer base could not be the same, as they form two different spheres of the business.
                </div>
                <div className='leading-[27px] py-3 text-grayColor'>
                  A business that pitches its products and services in the same way across months or years is bound to reach a stagnant number graph at a point, throwing light on the similar strategies paving the way for a steady flat line or decline in the graph.
                </div>
                <div className='leading-[27px] py-3 text-grayColor'>
                  Alternative strategies that cover the changing needs help entice potential new customers, who would eventually become strengths for the business.
                </div>
              </div>
              <div >
                <h1 className='text-[30px] font-bold leading-[27px] ' >Be actively receptive to feedback</h1>
                <div className='leading-[27px] py-3 text-grayColor'>
                  Businesses should never lose their touch when it comes to customer satisfaction. Customer feedback about products and services are real-time windows into learning what ticks. Businesses that let the negative reviews and comments go unaddressed face the risk of customers leaving.
                </div>
                <div className='leading-[27px] py-3 text-grayColor'>
                  Gathering customer feedback should be a regular activity, and businesses must employ automated tools to ensure that customers have clear channels to provide their comments and suggestions. Businesses should then focus on correcting even the seemingly minor issues that arise with their customers.
                </div>
                <div className='leading-[27px] py-3 text-grayColor'>
                  Any potential customer checks out a business’s customer satisfaction levels before deciding to invest their resources in the company. Businesses that are receptive to feedback and implement corrective measures get favorable reviews and exponentially increase their chances of growing their customer base.
                </div>
                <div className='leading-[27px] py-3 text-grayColor'>
                  Collecting and sorting through customer feedback can be simplified with the use of automated tools. We at Success4 automate the entire process by providing tools that leverage meaningful insights to focus your team’s efforts on the highest value activities with your highest value customers through success plans, KPIs, and playbooks.
                </div>
              </div>
              <div>
                <h1 className='text-[30px] font-bold leading-[27px] ' >Predict market dynamics</h1>
                <div className='leading-[27px] py-3 text-grayColor'>
                  Businesses that are constantly aware of the changing market dynamics are already successful by theory. When they use this updated knowledge to strategize and alter their business practices, their route to success becomes clearer.
                </div>
                <div className='leading-[27px] py-3 text-grayColor'>
                  When a business anticipates its customers’ needs and provides products and services that make it indispensable, the need in the market grows and the business reaches to meet it, ensuring customer satisfaction on the way, and leading to comfortable numbers in customer growth.
                </div>
              </div>
              <div>
                <h1 className='text-[30px] font-bold leading-[27px]' >Approach potential customers with newer plans</h1>
                <div className='leading-[27px] py-3 text-grayColor'>
                  Alongside the correct prediction of market dynamics comes the next requirement – approaching potential customers with newer plans. Starting with relevant offers in the costs of products and services, businesses need to have newer plans once they have strategized to welcome an inflow of new customers.
                </div>
                <div className='leading-[27px] py-3 text-grayColor'>
                  Allowing room for newer customers during product launches and engaging them in the processes of development and enhancement makes them feel good about the company’s services. With this method, the customer success rates skyrocket, and the new customers spread the word quicker about how your business has exciting plans for those who engage with it.
                </div>
              </div>
              <div>
                <h1 className='text-[30px] font-bold leading-[27px]' >Allow for customer interaction at all levels</h1>
                <div className='leading-[27px] py-3 text-grayColor'>
                  Customers love accessible businesspeople. Actively collecting and responding to customer feedback is a starting point for customer retention and allowing for customer interaction at all levels provides easier pathways for newer customers to try out a business’s products and services with more conviction.
                </div>
                <div className='leading-[27px] py-3 text-grayColor'>
                  Having trained personnel who can handle requests, comments, suggestions, and general feedback at all levels ensures customer satisfaction and thereby customer growth in future.
                </div>
                <div className='leading-[27px] py-3 text-grayColor'>
                  When businesses are prompt with their responses, the relationship between them and their customer base grows stronger and more meaningful.
                </div>
                <div className='leading-[27px] py-3 text-grayColor'>
                  Businesses could prompt interactions with customers even with promotional material, by providing innovative queries, meaningful and rewarding surveys, and options for clients to interact with the business. Providing social media handles as part of the promotional material, and including specialized communication channels are certain ways in which businesses can focus on customer growth. However, these communication channels must be active and responsive at all times, because the attention that businesses get on social media could make or break their strategies.
                </div>
              </div>
              <div>
                <h1 className='text-[30px] font-bold leading-[27px]' >Embrace the magic of ‘free’ and ‘trials’</h1>
                <div className='leading-[27px] py-3 text-grayColor'>
                  In a competitive world, the magic words of any business would be ‘free’ and ‘trial’.
                </div>
                <div className='leading-[27px] py-3 text-grayColor'>
                  The biggest wall that every business has to surmount is the one surrounding money. Even the customers who are warming up to the idea of approaching a business shy away at the last moment on seeing how much a product or service costs. Providing attractive discounts and offering certain products for free or services on trial could allow the customers a chance to see what their experience will be when they are completely engaged with the business.
                </div>
                <div className='leading-[27px] py-3 text-grayColor'>
                  Free trials that do not require a customer to enter into payment agreements could be an excellent opportunity for a demo of the products and services a business offers.
                </div>
                <div className='leading-[27px] py-3 text-grayColor'>
                  Further, businesses can use these trials to build goodwill with their potential customers, and at the least, convert these into testimonials with a well-timed, strategically-placed request to the customers who have used the product/service. The curiosity and the subsequent satisfaction levels with aid in customer growth.
                </div>
                <div className='leading-[27px] py-3 text-grayColor'>
                  All strategies mentioned as part of customer success work for customer retention too, and automating these tasks would make sure that customers are not left unattended. Businesses can use the tools we at Success4 provide, and manage their operations more efficiently. Our tools offer a multitude of functions that automate tasks of varying intensity, leaving businesses more time to focus on their strategies and development.
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className='flex justify-between mb-[5vh] md:px-[10%] px-[2%]'>
        <div className='flex md:flex-row flex-col gap-5'>
          <div className='w-[10vw]'>
            <a href='/how-to-quantify-customer-success'>
              <img height='87' width='87' src={Quantify} />
            </a>
          </div>
          <div>
            <h3 className='text-[12px]'>PREVIOUS ARTICLE</h3>
            <p className='text-[14px] font-bold text-grayColor'>How to Quantify Customer Success</p>
          </div>
        </div>
        <div className='flex md:flex-row flex-col gap-5'>
          <h1>{''}</h1>
        </div>
      </div>
      <div className='w-full bg-ghostWhite mb-[2%] md:px-[10%] px-[2%]'>
        <h2 className={`text-black  font-bold text-[24px] text-center`}>Related Posts</h2>
        <div className='pt-[40px]'>
          <div className='flex items-center justify-center '>
            <div className={`md:grid md:grid-cols-3  md:grid-flow-row grid-cols-1 md:leading-[50px] grid gap-y-10 gap-x-5`}>
              <a href='/who-should-own-customer-success'>
                <img src={Ownership} />
                <h2 className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>Who should own Customer Success</h2>
                <p className='text-grayColor'>{'September 10, 2020'}</p>
              </a>
              <a href='/controlling-your-customer-success-data-in-the-cloud'>
                <img src={DataControl} />
                <h2 className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>Controlling Your Customer Success Data in the Cloud</h2>
                <p className='text-grayColor'>{'September 10, 2020'}</p>
              </a>
              <a href='/getting-full-roi-from-your-customer-success-investment-3'>
                <img src={RoiCs} />
                <h2 className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>Getting Full ROI from your Customer Success Investment</h2>
                <p className='text-grayColor'>{'September 10, 2020'}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <section className='font-nunito-sans  pb-[5vh] md:px-[10%] px-[2%]'>
        <div className=' bg-yellow  rounded-[2vh] md:flex-row md:items-center md:justify-center md:flex flex-col pt-8 px-8'>
          <div>
            <h3 className='lg:text-3xl md:text-xl font-bold pb-[5vh]'>Want to know how we can help you understand your customers better?</h3>
            <div className='flex md:flex-row flex-col gap-5'>
              <input
                className=" md:w-[25vw] border border-solid-gray p-3 border-[1px] rounded-[15px] hover:border-blue-500 focus:border-blue-500 focus:outline-none focus-within:border-blue-500"
                placeholder="Your Email Address"
              />
              <button className=' lg:w-[13vw] sm:w-[20vw]  mt-[2vh] w-[30vw] bg-black text-white h-[6vh] text-sm md:text-md xl:text-xl rounded-[1vh]'>Request Demo</button>
            </div>
          </div>
          <img src={Hand} />
        </div>
      </section>
    </div>
  )
}
