import React from 'react'
import QuantifyImg from '../../../../assets/quantify_cs.svg'
import Ownership from '../../../../assets/ownership_cs.svg'
import DataControlImg from '../../../../assets/data_control.svg'
import RoiCs from '../../../../assets/roi_cs.svg'
import Hand from '../../../../assets/hand.png'


export default function DataControl() {
    return (
        <div className='w-full' >
            <div className='mb-[5vh]'>
                <div className='mb-[5vh]'>
                    <div className='w-full absolute  '>
                        <img className='w-full xl:h-[calc(100vh-15vh)] object-cover' src={DataControlImg} />
                    </div>
                    <section className='relative flex flex-col items-center justify-end xl:h-[calc(100vh-15vh)]  bg-black bg-opacity-30  mb  text-white'>
                        <a href='/blog' className='pb-[2%] text-center'>Back to Blogs</a>
                        <h1 className='text-4xl font-bold pb-[2%] text-center'>Controlling Your Customer Success Data in the Cloud</h1>
                        <p className='pb-[5%] text-center'>Vivek / July 20, 2020</p>
                    </section>
                </div>
                <div className='w-full wrap text-[18px] '>
                    <div className='flex flex-col items-center justify-center '>
                        <div className='md:px-[20%] px-[5%] '>
                            <div className=' wrap  leading-[27px] py-3 text-grayColor'>Customer success is vital to your business and so is the integrity and security of your data. With the current rate of technology advancement and innovation today, we are producing and collecting more data than ever before. That data needs to be stored securely and in compliance with the regulatory framework of your industry.</div>
                            <div className=' wrap  leading-[27px] py-3 text-grayColor'>You want to remain cost-effective, and nimble to scale, but security remains paramount. Federal and international penalties can reach into the tens of millions of dollars, while the overarching damage that can occur from a major data breach may be insurmountable.</div>
                            <div className=' wrap  leading-[27px] py-3 text-grayColor'>In the effort to achieve customer success, a reliable, secure solution is needed to keep systems up and running and protect your data from cyberthreats or unauthorized access. While security in the cloud today is better than it ever has been, the potential for a breach is ever-present, calling for a more robust approach.</div>
                            <div>
                                <h1 className=' wrap text-[30px] leading-[27px] py-3 '>Are you concerned about managing customer data in the cloud?</h1>
                                <div className='leading-[27px] py-3 text-grayColor'>
                                    Cloud-based solutions are convenient, but if your business requires full control of the application environment and your data due to contractual or compliance needs, a hybrid-cloud solution may be more appropriate.
                                </div>
                                <div className='leading-[27px] py-3 text-grayColor'>
                                    Beyond data security, for some companies and situations, migrating your entire customer success workload and the associated customer data to the cloud can be a big leap.
                                </div>
                                <div className='leading-[27px]  text-grayColor'>
                                    Some of your concerns may include:
                                </div>
                                <ul className='text-grayColor list-disc'>
                                    <li>Control: How much control do I have of my systems in case of disaster?</li>
                                    <li>Cost: How will scale impact the economy of my solution?</li>
                                    <li>Availability: Will my systems and applications be online and ready to go when I need them?</li>
                                    <li>Performance: Will my systems and data perform as they should?</li>
                                    <li>Security: Will I have control over how my data is accessed?</li>
                                </ul>
                            </div>
                            <div >
                                <h1 className='text-[30px] font-bold leading-[27px] py-3' >Benefits of on-premise, hybrid-cloud, and private cloud data implementations</h1>
                            </div>
                            <div className='leading-[27px] wrap text-grayColor py-3'>
                                On-premise control of your systems, data, and applications gives you complete oversight over how your data is secured. You retain full application control and can securely manage all your data within your on-premise environment.
                            </div>
                            <div className='leading-[27px] wrap text-grayColor py-3'>
                                Performance is enhanced because you are hosting the application adjacent to your critical business applications, minimizing data redundancy, and ensuring data locality and availability at all times.
                            </div>
                            <div className='leading-[27px] wrap text-grayColor py-3'>
                                Whether you operate in an on-premise environment, a private cloud, or a hybrid-cloud infrastructure, Success4 can be tailored to meet your specific needs. Our cloud solutions are fully adaptable to even the most highly regulated security frameworks, ensuring your business continuity.
                            </div>
                            <h1 className=' wrap text-[30px] leading-[27px] py-3 '>Compliance concerns addressed</h1>
                            <div className='leading-[27px] wrap text-grayColor py-3'>
                                If you have special security requirements, such as if you are limited by a regulatory framework that prohibits an all-in migration to the cloud, the Success4 platform can be adapted to it.
                            </div>
                            <div className='leading-[27px] wrap text-grayColor py-3'>
                                Some of the frameworks we support include payment processing regulations (PCI DSS), the HIPAA requirements for healthcare organizations, and FISMA. We are also compliant with the GDPR as well as the latest data protection laws elsewhere in the world.
                                A solution tailored to your unique needs <br />
                                Some of the frameworks we support include payment processing regulations (PCI DSS), the HIPAA requirements for healthcare organizations, and FISMA. We are also compliant with the GDPR as well as the latest data protection laws elsewhere in the world.
                                A solution tailored to your unique needs <br />Many companies today are opting for a hybrid-cloud solution that combines the best of both worlds. A hybrid architecture gives you complete control of your data and delivers all the security, performance, and availability advantages of an on-premise solution – with the added flexibility and control.<br />Using a modern hybrid-cloud architecture<br />
                                If full control of your data is a requirement for you, consider looking for a customer success platform that can offer you full deployment flexibility so you can manage and control where your data lives. As an example, Success4 uses a Kubernetes managed Hybrid-Cloud Container Platform Architecture.

                            </div>
                            <div className='leading-[27px] wrap text-grayColor py-3'>
                                Deployment flexibility allows you to decide where you want your customer success solution and data to reside; your data center, your private cloud, your hybrid cloud, the public cloud or our secure SaaS infrastructure. This helps to ensure you stay compliant with all regulatory frameworks while assuring your data integrity. This also allows you to choose an approach that aligns with your security and compliance practices, a critical feature for companies who manage sensitive applications and customer data.
                            </div>
                            <div className='leading-[27px] wrap text-grayColor py-3'>
                                A hybrid cloud approach can also support a cloud-based backup strategy, a disaster recovery plan (DRP), or be a driver of cost-efficiency.
                            </div>
                            <div className='leading-[27px] wrap text-grayColor py-3'>
                                Success4’s hybrid-cloud approach gives you complete control over your data while ensuring security from end-to-end.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-between mb-[5vh] md:px-[10%] px-[2%]'>
                <div className='flex md:flex-row flex-col gap-5'>
                    <div >
                        <a href='/4-effective-customer-success-strategies-for-2019-2'>
                            <img height='87' width='87' src={RoiCs} />
                        </a>
                    </div>
                    <div className='w-[10vw]'>
                        <h3 className='text-[12px]'>PREVIOUS ARTICLE</h3>
                        <p className='text-[14px] font-bold text-grayColor'>Getting Full ROI from your Customer Success Investment</p>
                    </div>
                </div>
                <div className='flex md:flex-row flex-col gap-5'>
                    <div>
                        <a href='/how-to-quantify-customer-success'>
                            <img height='87' width='87' src={Ownership} />
                        </a>
                    </div>
                    <div className='w-[10vw]'>
                        <h3 className='text-[12px]'>NEXT ARTICLE</h3>
                        <p className='text-[14px] font-bold text-grayColor'>Who should own Customer Succes</p>
                    </div>
                </div>
            </div>
            <div className='w-full bg-ghostWhite mb-[2%] md:px-[10%] px-[2%] py-[2%]'>
                <h2 className={`text-black  font-bold text-[24px] text-center`}>Related Posts</h2>
                <div className='pt-[40px]'>
                    <div className='flex items-center justify-center '>
                        <div className={`md:grid md:grid-cols-3  md:grid-flow-row grid-cols-1 md:leading-[50px] grid gap-y-10 gap-x-5`}>
                            <a href='/how-to-quantify-customer-success'>
                                <img src={QuantifyImg} />
                                <h2 className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>How to Quantify Customer Success</h2>
                                <p className='text-grayColor'>{'December 2, 2020'}</p>
                            </a>
                            <a href='/who-should-own-customer-success'>
                                <img src={Ownership} />
                                <h2 className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>Who should own Customer Success</h2>
                                <p className='text-grayColor'>{'September 10, 2020'}</p>
                            </a>
                            <a href='/getting-full-roi-from-your-customer-success-investment-3'>
                                <img src={RoiCs} />
                                <h2 className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>Getting Full ROI from your Customer Success Investment</h2>
                                <p className='text-grayColor'>{'September 10, 2020'}</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <section className='font-nunito-sans  pb-[5vh] md:px-[10%] px-[2%]'>
                <div className=' bg-yellow  rounded-[2vh] md:flex-row md:items-center md:justify-center md:flex flex-col pt-8 px-8'>
                    <div>
                        <h3 className='lg:text-3xl md:text-xl font-bold pb-[5vh]'>Want to know how we can help you understand your customers better?</h3>
                        <div className='flex md:flex-row flex-col gap-5'>
                            <input
                                className=" md:w-[25vw] border border-solid-gray p-3 border-[1px] rounded-[15px] hover:border-blue-500 focus:border-blue-500 focus:outline-none focus-within:border-blue-500"
                                placeholder="Your Email Address"
                            />
                            <button className=' lg:w-[13vw] sm:w-[20vw]  mt-[2vh] w-[30vw] bg-black text-white h-[6vh] text-sm md:text-md xl:text-xl rounded-[1vh]'>Request Demo</button>
                        </div>
                    </div>
                    <img src={Hand} />
                </div>
            </section>
        </div>
    )
}
