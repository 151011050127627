import React, { useState, useEffect } from 'react'
import BannerImg from '../../../assets/bannerimagemobile.png'
import EasyIcon from '../../../assets/easy-icon.svg'
import SecureIcon from '../../../assets/secure-icon.svg'
import InsightIcon from '../../../assets/insightfull-icon.svg'
import FieldAssist from '../../../assets/trustedby/FieldAssist-Logo.png'
import Adapt from '../../../assets/trustedby/adapt-ai.png'
import ExtraEdge from '../../../assets/trustedby/extra-edge.png'
import Decisionminds from '../../../assets/trustedby/decisionminds.png'
import Iquanti from '../../../assets/trustedby/iquanti-s4.png'
import Joinneight from '../../../assets/trustedby/joineight-s4.png'
import Leenaai from '../../../assets/trustedby/leenaai-s4.png'
import MovieSync from '../../../assets/trustedby/moveinsync-logo-s4.png'
import Opsramp from '../../../assets/trustedby/opsramp-s4.png'
import Recko from '../../../assets/trustedby/recko-s4.png'
import SendSafely from '../../../assets/trustedby/sendsafely-s4.png'
import Singleops from '../../../assets/trustedby/singleops-s4.png'
import TalView from '../../../assets/trustedby/talview-s4.png'
import ImgFast from '../../../assets/img_fastest_value.svg'
import Imgscale from '../../../assets/img_scale.svg'
import Imghybrid from '../../../assets/img_hybrid_cloud.svg'
import ImgPredictiveAnalytics from '../../../assets/img_predictive_analytics.svg'
import Hand from '../../../assets/hand.png'
import Stages from './Stages/Stages'
import RequestDemo from '../../CommonFunction/PopHover/RequestDemo'
import TwoGridCol from '../../CommonFunction/TwoGridCol/TwoGridCol'

const imageComponents = [
  Decisionminds,
  Adapt,
  FieldAssist,
  ExtraEdge,
  Iquanti,
  TalView,
  Joinneight,
  MovieSync,
  Leenaai,
  Opsramp,
  Recko,
  SendSafely,
  Singleops,
];

const Home = () => {
  const [visibleLogos, setVisibleLogos] = useState([]);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setOffset((prevOffset) => (prevOffset + 1) % imageComponents.length);
      setVisibleLogos((prevVisibleLogos) => {
        const newLogos = [...prevVisibleLogos];
        newLogos.shift(); // Remove the last logo
        newLogos.push(imageComponents[(offset + newLogos.length) % imageComponents.length]); // Add a new logo at the beginning
        return newLogos;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [offset]);

  // Initialize visibleLogos with the first 7 logos
  useEffect(() => {
    setVisibleLogos(imageComponents.slice(0, 7));
  }, []);
  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleRequestDemoClick = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const s4List = [
    {
      Title: 'Fastest Time to Value',
      Image: ImgFast,      
      Order: { one: 'lg:order-1', two: 'lg:order-2' },
      Description: 'Quickly connect to your customer data and onboard your team. Easily customize the experience for your business. Leverage our out of the box best practices and templates. Be up and running in less than a week.',
      List: ['Data Integrations', 'Drag & Drop UI Builder', 'Playbook templates']
    },
    {
      Title: 'Scale to reach every customer',
      Image: Imgscale,      
      Order: { one: 'lg:order-2', two: 'lg:order-1' },
      Description: 'Easily automate tech touch customer success through external process automation, triggers, and workflows. Leverage meaningful insights to focus your team’s efforts on the highest value activities with your highest value customers through success plans, KPIs, and playbooks',
      List: ['Success Plan', 'Playbook', 'Triggers', 'Automation']
    },
    {
      Title: 'Powerful Predictive Analytics',
      Image: ImgPredictiveAnalytics,      
      Order: { one: 'lg:order-1', two: 'lg:order-2' },
      Description: 'Data science modeling, KPIs, and health scores give you powerful predictive insights into your customers. Triggers and alerts prescribe the right actions at the right times.',
      List: [ 'Report Visualizations','Triggers','KPIs','Alerts', 'Health Scores']
    },
    {
      Title: 'Industry’s only hybrid cloud solution',
      Image: Imghybrid,      
      Order: { one: 'lg:order-2', two: 'lg:order-1' },
      Description: 'On premise, your cloud, or our cloud, flexibly choose where you want your data to live and how you want your solution operated.',
      List: ['Customer Data Security','Hybrid Cloud','Firewall Protected']
    }
  ]

  return (
    <>
      <div className='sm:block font-nunito-sans  xl:bg-center  xl:bg-cover xl:h-[calc(100vh-11vh)] xl:bg-cover xl:bg-center bg-custom' style={{ backgroundImage: 'url("/banner1920.png")' }}>
        <div className='sm:w-full xl:w-[55vw]  h-full xl:items-center xl:justify-center md:flex  '>
          <div className='sm:w-full xl:w-[35vw] '>
            <h2 className='lg:text-6xl md:text-5xl  text-4xl text-center md:text-left'>Understand your customers better</h2>
            <div className='mt-3 md:mt-5 text-textGrayColor font-thin'>
              <h4 className='text-base sm:text-lg text-center md:text-left'>
                We provide the right tools that do all the heavy lifting so you can focus on your customer success strategy.
              </h4>
            </div>
            <div className='mt-4 text-center md:text-left'>
              <button onClick={handleRequestDemoClick} className='my-2 mx-0 inline-block text-white rounded-md px-3 py-2 text-base bg-blueColor shadow-sm'>Request Demo</button>
            </div>
            {isPopupVisible && <RequestDemo onClose={handleClosePopup} />}
          </div>
          <div className='sm:flex xl:hidden items-center justify-center '>
            <img className='max-w-full' src={BannerImg} alt='Banner Image' />
          </div>
        </div>
      </div>
      <div className='font-nunito-sans bg-ghostWhite leading-[35px] p-[10px]'>
        <section className='text-center w-full leading-[35px]'>
          <div className=' mt-[30px]'>
            <h3 className='text-[25px] font-bold'>Our Core Values</h3>
          </div>
        </section>
        <section className=' gap-12 grid lg:grid-row-3 lg:grid-flow-col text-center text-grayColor flex justify-center items-center '>
          <div className='lg:w-[24vw]'>
            <p>
              <img src={EasyIcon} className='my-[2rem] mx-auto ' />
            </p>
            <h3 className=' mb-[2rem] mx-[auto] text-[40px] font-bold lining-nums '>Easy</h3>
            <div className='lg:h-[20vh]'>
              <p className='text-[18px]'>Easy to use, deploy, and customize to your businesss needs</p>
              <a href='/about' className='text-[18px] text-red-500'>Know more</a>
            </div>
          </div>
          <div className='lg:w-[24vw]'>
            <p>
              <img src={SecureIcon} className='my-[2rem] mx-auto ' />
            </p>
            <h3 className='mb-[2rem] mx-[auto] text-[40px] font-semibold '>Secure</h3>
            <div className='lg:h-[20vh]'>
              <p className='text-[18px]'>The industry’s only hybrid cloud solution (so your customer data never leaves your cloud)</p>
              <a href='/about' className='text-[18px] text-red-500'>Know more</a>
            </div>
          </div>
          <div className='lg:w-[24vw]'>
            <p>
              <img src={InsightIcon} className='my-[2rem] mx-auto ' />
            </p>
            <h3 className='mb-[2rem] mx-[auto] text-[40px] font-semibold '>Insightful</h3>
            <div className='lg:h-[20vh]'>
              <p className='text-[18px]'>A unified view of your customers and how to best serve them</p>
              <a href='/about' className='text-[18px] text-red-500'>Know more</a>
            </div>
          </div>
        </section>
      </div>
      <div className='font-nunito-sans pt-[50px] pb-[70px]'>
        <div className='p-[10px] mb-[20px]'>
          <div className='mt-[10px] text-center font-bold text-[25px]'>Trusted by</div>
        </div>
        <section className=' md:px-[10%] px-[2%]'>
          <div className='relative box-border overflow-hidden flex items-center fixed h-[15vh]'>
            {visibleLogos.map((ImageComponent, index) => (
              <img src={ImageComponent} key={index} className='md:w-[12vw]  px-[19px] ' />
            ))}
          </div>
        </section>
      </div>
      <div>
        <TwoGridCol listData={s4List} />
      </div>
      <div>
      <Stages />
      </div>
      <section >
        <div className='font-nunito-sans flex mx-auto relative'>
          <div className='w-full '>
            <h2 className='text-center text-[25px] font-bold mt-[6vh] mb-[3vh] mx-auto'>Security Certification & Compliance</h2>
            <section className='pb-[30px]  flex items-center justify-center '>
              <div className=' mx-auto lg:flex lg:text-center lg:w-[50vw] '>
                <div className='lg:w-[45vw]'>SOC2 TYPE II CERTIFIED</div>
                <div className='lg:w-[45vw]'>ISO 27001 CERTIFIED</div>
                <div className='lg:w-[45vw]'>VAPT COMPLIANT</div>
              </div>
            </section>

          </div>
        </div>
      </section>
      <section className='font-nunito-sans  pb-[5vh] md:px-[10%] px-[2%]'>
        <div className=' bg-yellow  rounded-[2vh] md:flex-row md:items-center md:justify-center md:flex flex-col pt-8 px-8'>
          <div>
            <h3 className='lg:text-3xl md:text-xl font-bold '>Want to know how we can help you understand your customers better?</h3>
            <button className=' lg:w-[13vw] sm:w-[20vw]  mt-[2vh] w-[30vw] bg-black text-white h-[6vh] text-sm md:text-md xl:text-xl rounded-[1vh]'>Request Demo</button>
          </div>
          <img  src={Hand} />
        </div>
      </section>
      {/* need to collect the badge */}
      <section>
        {/* <div className='font-nunito-sans md:flex md:items-center md:justify-center flex flex-col'>
          <div className='flex justify-between gap-10'>
            <img src={Leenaai} className='lg:h-[60px] lg:max-w-[200px]'/>
            <img src={MovieSync} className='lg:h-[60px] lg:max-w-[200px]'/>
          </div>
        </div> */}
      </section>
    </>
  )

}

export default Home