import React , {useState} from 'react'
import Header from './Header'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'

const Navbar = () => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [openMobileDropdown, setOpenMobileDropdown] = useState(null);
  const [isHovered, setIsHovered] = useState(null);
 
  const toggleDropdown = (label) => {
    setOpenDropdown((prev) => (prev === label ? null : label));

  };
  
  const mobileToggleDropdown = (label) => {
    setOpenMobileDropdown((prev) => (prev === label ? null : label));

  };
  const hoverDropdown = (label) => {
    setIsHovered((prev) => (prev === label ? null : label));

  };

 

  const closeToggle=()=>{
    toggleDropdown(null)
    hoverDropdown(null)
  }

  return (
    <div>
      <Header hoverDropdown={hoverDropdown} toggleDropdown={toggleDropdown} mobileToggleDropdown={mobileToggleDropdown} openDropdown={openDropdown} isHovered={isHovered}  openMobileDropdown={openMobileDropdown}/>
      <div onMouseEnter={closeToggle}>
      <Outlet/>
      </div>
      <div onMouseEnter={closeToggle}>
      <Footer   />
      </div>
    </div>
  )
}

export default Navbar