import React from 'react'
import Navbar from './Layout/Navbar/Navbar'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './Components/pages/Home/Home'
import './App.css'
import PageLayout from './Components/pages/Page/PageLayout'
import Blog from './Components/pages/Resources/Blog/Blog'
import Pricing from './Components/pages/Pricing/Pricing'
import AboutUs from './Components/pages/AboutUs/AboutUs'
import GrowPage from './Components/pages/Resources/Blog/GrowPage'
import Quantify from './Components/pages/Resources/Blog/Quantify'
import CustomerSuccess from './Components/pages/Resources/Blog/CustomerSuccess'
import DataControl from './Components/pages/Resources/Blog/DataControl'
import Strategy from './Components/pages/Resources/Blog/Strategy'
import RoiCs from './Components/pages/Resources/Blog/RoiCs'


export default function App() {
  return (
    <div>
        <Router>
          <Routes>
            <Route path='/' element={<Navbar/>}>
              <Route index element={<Home/>}/>
              <Route exact path='/customer360' element={<PageLayout id='customer360' page='solution' />}/>
              <Route exact path='/prevent-churn' element={<PageLayout id='preventchurn'/>}/>
              <Route exact path='/customer-onboarding' element={<PageLayout id='customeronboarding'/>}/>
              <Route exact path='/high-touch-customer-success' element={<PageLayout id='hightouchcustomersuccess'/>}/>
              <Route exact path='/tech-touch-customer-success' element={<PageLayout id='techtouchcustomersuccess'/>}/>
              <Route exact path='/customer-expansion' element={<PageLayout id='customerexpansion'/>}/>
              <Route exact path='/renewal-management' element={<PageLayout id='renewalmanagement'/>}/>
              <Route exact path='/risk-management' element={<PageLayout id='riskmanagement'/>}/>
              <Route exact path='/customer-health' element={<PageLayout id='customerhealth'/>}/>
              <Route exact path='/success-planning' element={<PageLayout id='successplanning'/>}/>
              <Route exact path='/product-adoption' element={<PageLayout id='productadoption'/>}/>
              <Route exact path='/playbook' element={<PageLayout id='playbook'/>}/>
              <Route exact path='/customer-centric-solution' element={<PageLayout id='customercentricsolution'/>}/>
              <Route exact path='/customer-collaboration' element={<PageLayout id='customercollaboration'/>}/>
              <Route exact path='/stakeholder-alignment' element={<PageLayout id='stakeholderalignment'/>}/>
              <Route exact path='/voice-of-customer' element={<PageLayout id='voiceofcustomer'/>}/>              
              <Route exact path='/ai-email-generator' element={<PageLayout id='aiemailgenerator'/>}/>
              <Route exact path='/data-integration' element={<PageLayout id='dataintegration'/>}/>    
              <Route exact path='/external-process-automation' element={<PageLayout id='externalprocessautomation'/>}/>          
              <Route exact path='/ui-builder' element={<PageLayout id='uibuilder'/>}/>
              <Route exact path='/triggered-alerts' element={<PageLayout id='triggeredalerts'/>}/>
              <Route exact path='/success-plan' element={<PageLayout id='successplan'/>}/>
              <Route exact path='/custom-data-attribute' element={<PageLayout id='customdataattribute'/>}/>
              <Route exact path='/calendar-email-integration' element={<PageLayout id='calendaremailintegration'/>}/>             
              <Route exact path='/kpis' element={<PageLayout id='kpis'/>}/>              
              <Route exact path='/sponser-tracking' element={<PageLayout id='sponsertracking'/>}/>
              <Route exact path='/customer-success-manager' element={<PageLayout id='customersuccessmanager'/>}/>             
              <Route exact path='/executives-cxo' element={<PageLayout id='executives'/>}/>              
              <Route exact path='/customer-success-operations' element={<PageLayout id='customersuccessoperation'/>}/>
              <Route exact path='/grow-your-business-by-growing-customer-numbers' element={<GrowPage/>}/>
              <Route exact path='/how-to-quantify-customer-success' element={<Quantify/>}/>
              <Route exact path='/who-should-own-customer-success' element={<CustomerSuccess/>}/>
              <Route exact path='/getting-full-roi-from-your-customer-success-investment-3' element={<RoiCs/>}/>
              <Route exact path='/controlling-your-customer-success-data-in-the-cloud' element={<DataControl/>}/>
              <Route exact path='/4-effective-customer-success-strategies-for-2019' element={<Strategy/>}/>
              <Route exact path='/blog' element={<Blog />}/>
              <Route exact path='/pricing' element={<Pricing />}/>
              <Route exact path='/about' element={<AboutUs />}/>
            </Route>            
          </Routes>
        </Router>
    </div>
  )
}
