import React, { useLayoutEffect, useState } from 'react';
import { pages } from './metadata';
import ComonPage from './CommonPage';

const PageLayout = (props) => {
    console.log(props.page)
    const [metadata, setMetaData] = useState([]);
    useLayoutEffect(() => {
        setMetaData(pages[props.id]);
    }, [props.id]);

    

    return (
        <>
            <div cds-layout='p:lg'>
                <ComonPage metadata={metadata} />
            </div>
        </>
    );
};

export default React.memo(PageLayout);
