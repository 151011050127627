import React from 'react'
import CompanyRaftki from '../../../assets/Company-rafiki-3.svg'
import Pattern from '../../../assets/vector/pattern.svg'
import Carousel from '../../CommonFunction/Carousel'
import Balaji from '../../../assets/Team/Balaji-Rajagopalan.png'
import Vivek from '../../../assets/Team/Vivek.png'
import Matt from '../../../assets/Team/Matt-Duke.png'
import Mark from '../../../assets/Team/Mark-Tonnesen.png'
import MotivationImg from '../../../assets/motivation-1.svg'
import Hand from '../../../assets/hand.png'

export default function AboutUs() {
    const teamData = [
        {
            img: Balaji,
            width: '300',
            height: '50',
            name: 'Balaji Rajagopalan',
            designation: 'Founder & CEO',
            description: 'Balaji is a sought-after Bay Area Industry veteran in the BI & Analytics space. He has 20+ years of experience helping fortune 500 companies transform their BI landscape.'

        },
        {
            img: Vivek,
            width: '400',
            height: '50',
            name: 'Vivek Raghunathan',
            designation: 'Head of Engineering',
            description: 'Vivek Raghunathan is a problem solver at heart and he thrives in challenging fast-paced environments. He has been an engineering leader for more than 10 years and worked at companies like Juniper Networks, Intel etc.'

        },
        {
            img: Matt,
            width: '450',
            height: '50',
            name: 'Matt Duke',
            designation: 'Advisor',
            description: 'Matt Duke is a pioneering, can-do executive leader with more than 20 years of experience in big data analytics, business strategy, business operations, inside sales, and customer success and worked at companies like Cisco, Oracle, and Facebook.'

        },
        {
            img: Mark,
            width: '500',
            height: '50',
            name: 'Mark Tonnesen',
            designation: 'Advisor',
            description: 'Mark Tonnesen is a driven technology thought leader and senior operations executive, with a proven reputation for managing enterprise-wide technology transformations and creating and launching new business delivery models. He worked at Cisco, Logitech, McAfee, Neustar to name a few.'

        },
    ]
    return (
        <div>
            <div className='md:px-[10%]  px-[2%] pt-[40px] '>
                <div className='flex md:flex-row flex-col md:items-center md:justify-between w-full'>
                    <div className='w-full'>
                        <h3 className='text-3xl font-bold md:leading-[100px] text-center md:text-left'>About Us</h3>
                        <h5 className='text-[21px] text-grayColor text-center md:text-left'>Success4, Inc was founded in 2016, it centralizes data from all customer interactions and data sources, monitors health and product usage, and predicts customer behavior so that the customer success teams can proactively engage with their customers in a most meaningful way.
                        </h5>
                    </div>
                    <div className='relative content-start wrap p-[10px] w-full ' ><img className=' align-middle inline-block' src={CompanyRaftki} /></div>
                </div>
            </div>
            <div className='bg-ghostWhite w-full'>
                <div className='md:px-[10%]  px-[2%] py-[3%]  '>
                    <div className='flex flex-col items-center text-center w-full pb-[5vh] lg:leading-10'>
                        <h2 className='text-3xl font-semibold'>Easy. Secure. Insightful</h2>
                        <p className='text-grayColor  '>Our three core values for a complete Customer Success Software</p>
                    </div>
                    <div >
                        <Carousel />
                    </div>
                </div>
            </div>
            <div style={{ backgroundImage: "url(" + Pattern + ")" }}>
                <div className={`md:px-[10%] px-[2%]  md:pb-[20vh] `} >
                    <div className=' w-full  items-center justify-center'>
                        <h2 className='text-center text-2xl lg:text-3xl font-bold p-[5vh]'>Our Team</h2>
                    </div>
                    <div className='grid lg:grid-cols-2 lg:grid-flow-row gap-10 grid-cols-1 grid-flow-row'>
                        {teamData.map((data, index) => {
                            return (
                                <div key={index} className='flex lg:w-[35vw] p-5 gap-5 grid-row-2 shadow-md bg-white'>
                                    <div >
                                        <img width={data.width} height={data.height} src={data.img} />
                                    </div>
                                    <div>
                                        <h2 className='font-nunito text-2xl font-bold'>{data.name}</h2>
                                        <h2 className='font-nunito text-xl font-medium'>{data.designation}</h2>
                                        <h2 className='font-nunito text-[18px] text-grayColor pt-[10px] pb-[40px]'>{data.description}</h2>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <section className='w-full bg-ghostWhite '>

                <div className='md:px-[10%] px-[2%] py-[5%]'>
                    <div className='flex md:flex-row flex-col items-center  w-full'>
                        <div className='hidden md:block relative content-start wrap p-[10px]  w-full' >
                            <img src={MotivationImg} />
                        </div>
                        <div className=' w-full '>
                            <div className='md:w-[30vw]'>
                                <h3 className='text-3xl font-medium md:w-[20vw] text-center md:text-left'>Looking for a good <span className='text-blueColor'> Opportunity?</span></h3>
                                <h5 className='text-[21px] pt-5 pb-5 text-grayColor text-center  md:text-left'>Ready to be challenged to do the best work of your life? We’re hiring!
                                </h5>
                                <div className='mt-8 text-center md:text-left'>
                                    <button className='my-2 mx-0 inline-block text-white rounded-md px-10 py-4 text-base bg-black  shadow-sm'>See Openings</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section className='flex items-center justify-center pb-[5vh]  md:px-[10%] px-[2%]'>
                <div className=' bg-yellow  rounded-[2vh] md:flex-row md:items-center md:justify-center flex flex-col pt-8 '>
                    <div>
                        <h3 className='lg:text-3xl md:text-xl font-bold md:pt-15 md:pl-20 md:pb-10'>Want to know how we can help you understand your customers better?</h3>
                        <button className='md:ml-[11vh] lg:w-[13vw] sm:w-[20vw]  mt-[2vh] w-[30vw] bg-black text-white h-[6vh] text-sm md:text-md xl:text-xl rounded-[1vh]'>Request Demo</button>
                    </div>
                    <img src={Hand} />
                </div>
            </section>
        </div>
    )
}
