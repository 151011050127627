import React,{useRef} from 'react';
import ContactBanner from '../../../assets/contact-banner.jpg';
import Close from '../../../assets/close.png';
import emailjs from '@emailjs/browser'

export default function RequestDemo({ onClose }) {
  const form = useRef();

  const sendEmail =(e)=>{
    e.preventDefault();
      emailjs.sendForm('service_8j074is','template_oaggp47',form.current,'bMD0qKDisJeHKNJBB')
      .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
  }
  return (
    <>
    <div className="fixed inset-0 bg-black bg-opacity-40 z-50 flex items-center justify-center shadow-md lg:p-5">
      <div className="max-w-screen-xs relative bg-white lg:p-5 rounded shadow-lg lg:max-w-screen-lg ">
        <button
          className="absolute top-0 right-0 p-3 cursor-pointer"
          onClick={onClose}
        >
          <img src={Close} alt="Close" />
        </button>
        <div className="flex flex-col lg:flex-row">
          <div className=" hidden lg:block  items-center justify-center">
            <img src={ContactBanner} className="w-full h-auto rounded-lg" alt="Contact Banner" />
          </div>
          <div className="flex-1">
            <form className="p-5 grid grid-col gap-5" ref={form} onSubmit={sendEmail}>
              <h1 className="text-2xl lg:text-4xl font-bold">Contact Us</h1>
              <div className="flex flex-col lg:flex-row gap-5">
                <input
                  className="w-full lg:w-1/2 border border-solid-gray p-3 border-[1px] rounded-[15px] hover:border-blue-500 focus:border-blue-500 focus:outline-none focus-within:border-blue-500"
                  placeholder="First Name" name='name'
                />
                <input
                  className="w-full lg:w-1/2 border border-solid-gray p-3 border-[1px] rounded-[15px] hover:border-blue-500 focus:border-blue-500 focus:outline-none focus-within:border-blue-500"
                  placeholder="Last Name" name='lastname'
                />
              </div>
              <input
                className="w-full border border-solid-gray p-3 border-[1px] rounded-[15px] hover:border-blue-500 focus:border-blue-500 focus:outline-none focus-within:border-blue-500"
                placeholder="Work E-mail " name='email'
              />
              <input
                className="w-full border border-solid-gray p-3 border-[1px] rounded-[15px] hover:border-blue-500 focus:border-blue-500 focus:outline-none focus-within:border-blue-500 "
                placeholder="Job title" name='role'
              />
              <textarea
                rows={6}
                className="w-full border border-solid-gray p-3 border-[1px] rounded-[15px] hover:border-blue-500 focus:border-blue-500 focus:outline-none focus-within:border-blue-500 "
                placeholder="Any Specific Requirement" name='requirement'
              />
              <button className="bg-blueColor text-white p-4 rounded-[4px] text-xl">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}