import React, { useState } from 'react';
import DropDown from '../../assets/vector/DownArrow.svg';
import UpArrow from '../../assets/vector/UpArrow.svg';
import EasyImg from '../../assets/img_easy.svg';
import SecureImg from '../../assets/img_secure.svg';
import InsightImg from '../../assets/img_insightful.svg';
import Next from '../../assets/vector/Next.svg';
import Previous from '../../assets/vector/Prev.svg';

export default function Carousel({ slides }) {
  const [open, setOpen] = useState(false);
  const [descriptionList, setDescriptionList] = useState();
  const [descriptionListId, setDescriptionListId] = useState();
  const [currentSlide, setCurrentSlide] = useState(0);

  const dropDownData = [
    {
        id: '01',
        name: 'Easy',
        image: EasyImg,
        description: 'It’s hard to be Easy',
        Data: [
            { id: '1', title: 'Easy to Use', description: 'Life’s too short to not love what you’re doing. We know CSMs become CSMs because they love helping customers. We believe they deserve tools that seemlessly fit into the way they work. Our aspiration is your CSMs love Success4 because of how easily it helps them to help their customers.' },
            { id: '2', title: 'Easy to Customize', description: 'We believe that flexibility and simplicity aren’t mutually exclusive. We take pride in making it easy to bring the uniqueness of your business into our platform. With Success4 it doesn’t take a computer science degree to have a fully tailored customer success solution matched to your business.' },
            { id: '3', title: 'Easy to Deploy', description: 'Your time and effort matter deeply to us. So we spend our time making Success4 simple and intuitive for you. This shows in how we allow you to implement in days vs months. Your team will be up and running in a few days so you can focus on more important things like better serving your customers.' }
        ]
    },
    {
        id: '02',
        name: 'Secure',
        image: SecureImg,
        description: 'Keep your customers close and their data even closer',
        Data: [
            { id: '1', title: 'Enterprise Grade Security', description: 'Your customer data is precious. your customers trust ou with it and you can trust Success4 to help ensure only the right people in your organisation have access to the right information at the right time.' },
            { id: '2', title: 'Hybrid Cloud', description: 'We’re fanatical about data security. So much so, we’ve developed the industry’s first and only hybrid cloud customer success solution. This provides you complete control on where your customer data lives; your data center, your private cloud, your public cloud, or on our SaaS platform' },
            { id: '3', title: 'Audit History', description: 'When it comes to security, we believe that you have to expect the unexpected. Therefore, It’s critical to know who’s using your customer data and what they’re using it for. Success4 ensures you always have that visibility.' }
        ],
    },
    {
        id: '03',
        name: 'Insightful',
        image: InsightImg,
        description: 'Smart teams deserve smart tools',
        Data: [
            { id: '1', title: 'Comprehensive', description: 'Deep understanding is the foundation for deep relationships. Our goal is to make Success4 your complete window into your customer. Since, the better you know your customer, the stronger the bond you can form.' },
            { id: '2', title: 'Predictive', description: 'We love to see the smile of a delighted customer. So we designed Success4 to help you anticipate your customers needs, because nothing brings a smile to someone’s face like providing what they need before they even know that they need it.' },
            { id: '3', title: 'Action Oriented', description: 'Data is great but its real value is in what it enables you to do. Our focus is to greatly reduce the time from data to insight to action. Success4 enables you to realise the full potential of your customer success team.' }
        ]
    }
  ]

  const openDropDown = (slideid, descriptionid) => {
    setOpen(!open);
    setDescriptionListId(descriptionid);
    const data = dropDownData.find((data) => data.id === slideid);
    data.Data.forEach((v) => {
      if (v.id === descriptionid) {
        setDescriptionList(v.description);
        setDescriptionListId(v.id);        
      }
    });
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % dropDownData.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + dropDownData.length) % dropDownData.length);
  };


  return (
    <div className='items-center justify-center flex relative w-full'>
      <div className='flex'>
        <div className='flex items-center justify-center'>
          <img
            src={Previous}
            alt='Previous Arrow'
            style={{  cursor: 'pointer' }}
            onClick={prevSlide}
          />
        </div>
        
        <div className=' transition-transform duration-500 ease-in-out transform p-10' style={{ transform: `translateX(-${currentSlide * 1}%)` }}>
          
          {dropDownData.map((data, index) => (
            <div key={index}>
              {index === currentSlide && (
                <div>
                  <div className='  flex justify-center items-center'>
                  <div className='absolute flex lg:w-[10vw] md:pr-[5%] items-center '>
                    <div className='hidden md:block'>
                    <p className='text-4xl md:pt-[40px] font-bold text-maximumBluePurple'>{data.id}</p>
                    </div>
                    <div className='md:hidden mb-[50px]  flex gap-5 '>
                    <p className='text-3xl md:pt-[20px] font-bold text-maximumBluePurple'>{data.id}</p>
                    <p className='relative text-4xl  text-black font-bold'>{data.name}</p>
                    </div>
                  </div>
                  </div>
                  <div className='flex md:flex-row flex-col'>
                    <div className=' hidden md:block'>
                      <img src={data.image} alt={data.name} />
                    </div>
                    <div >
                    <div className='md:block hidden'>
                    <p className='relative text-4xl text-black font-bold'>{data.name}</p>
                    </div>
                    <div className='flex flex-col  relative gap-3 '>
                      
                      <p className='lg:leading-[50px] leading-[20px]'>{data.description}</p>
                      {data.Data.map((list, key) => (
                        <div key={key} className='p-5 md:w-[30vw] bg-white rounded-2 flex justify-between cursor'>
                          <div className='font-bold'>{list.title}
                            {open && descriptionListId === list.id && (
                              <div className='text-wrap '>
                                <h4 className='text-md mt-[2vh] font-medium'>{descriptionList}</h4>
                              </div>
                            )}
                          </div>
                          {open ? (
                            <img
                              className='cursor-pointer'
                              src={UpArrow}
                              alt='Collapse'
                              onClick={() => openDropDown(data.id, list.id)}
                            />
                          ) : (
                            <img
                              className='cursor-pointer'
                              src={DropDown}
                              alt='Expand'
                              onClick={() => openDropDown(data.id, list.id)}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                    </div>
                    <div className='sm:block md:hidden'>
                      <img src={data.image} alt={data.name} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
       
        <div className='flex items-center justify-center'>
          <img
            src={Next}
            alt='Next'
            style={{  cursor: 'pointer' }}
            onClick={nextSlide}
          />
        </div>
      </div>
    </div>
  );
}