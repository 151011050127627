import React from 'react'
import QuantifyImg from '../../../../assets/quantify_cs.svg'
import Ownership from '../../../../assets/ownership_cs.svg'
import CustomerStrategy from '../../../../assets/strategies_2019.svg'
import DataControlImg from '../../../../assets/data_control.svg'
import RoiCsImg from '../../../../assets/roi_cs.svg'
import Hand from '../../../../assets/hand.png'


export default function RoiCs() {
    return (
        <div className='w-full' >
            <div className='mb-[5vh]'>
                <div className='mb-[5vh]'>
                    <div className='w-full absolute  '>
                        <img className='w-full xl:h-[calc(100vh-15vh)] object-cover' src={RoiCsImg} />
                    </div>
                    <section className='relative flex flex-col items-center justify-end xl:h-[calc(100vh-15vh)]  bg-black bg-opacity-30  mb  text-white'>
                        <a href='/blog' className='pb-[2%] text-center'>Back to Blogs</a>
                        <h1 className='text-4xl font-bold pb-[2%] text-center'>Getting Full ROI from your Customer Success Investment</h1>
                        <p className='pb-[5%] text-center'>Vivek / January 11, 2020</p>
                    </section>
                </div>
                <div className='w-full wrap text-[18px] '>
                    <div className='flex flex-col items-center justify-center '>
                        <div className='md:px-[20%] px-[5%] '>
                            <h1 className=' wrap text-[30px] leading-[27px] py-3 '>Getting the most Return from your Customer Success Investment with Automation</h1>
                            <div className=' wrap  leading-[27px] py-3 text-grayColor'>It’s not a matter of whetherif you should invest in Customer Success, you’ve already done that. It’s not a small investment to hire a new team, build the right strategy and transform many of the processes that drove drive your business. But making sure that investment sees its highest return is not always easy. Having recently talked with several organizations I see a lot of reorganization with Customer Success being the pawn between groups. While Customer Success goals may be the same, each groups charter can influence how the team achieves those goals. Budgets can also be influenced and impact the effectiveness of the original investment. The one constant I have seen is that providing Customer Success teams the right tools, to automate tasks, provides the greatest ROI.</div>

                            <div>
                                <h1 className=' wrap text-[30px] leading-[27px] py-3 '>Let’s start by looking at how we would measure ROI for a Customer Success Team.</h1>

                                <ol type='1' className='text-grayColor'>
                                    <li>Reduce churn – You should be able to measure the number of customers the Customer Success team touches and a historical impact of those customers</li>
                                    <li>Time to Value – The quicker a customer adopts your product or service, the faster they consume what they bought and the faster they need more</li>
                                    <li>Expansion – Happy customers buy more, but more importantly, helping customers identify areas they can take advantage of your product or services leads to more sales?</li>

                                </ol>
                            </div>

                            <div className='leading-[27px] wrap text-grayColor py-3'>
                                These are traditional revenue areas, renewals and upsell, that used to be driven from a sales organization. Customer Success compliments those efforts (or in some cases replaces them) and should be measured on its success in doing so. But just as you provide the right tools, such as CRM, to your sales team to drive revenue, automation is key for your Customer Success team. Let’s look at some metrics to prove that point.
                            </div>
                            <div className='leading-[27px] wrap text-grayColor py-3'>
                                Let’s assume that a CSM has 100 customers and that each customer has an ARR of $10,000. That’s a $1,000,000 ARR per CSM.
                            </div>
                            <div className='leading-[27px] wrap text-grayColor py-3'>
                                If you use a 40-hour work week, on average, you will have 24 minutes for each of your 100 customers. Without automation the majority of that time is spent doing research, finding data from multiple systems, typing up emails, determining next steps. You have no way to determine which of those steps impacted that customer or drove the results you were anticipating. It’s like asking for marketing team to set up campaigns by manually talking to each prospect and having no way to track what was successful.
                            </div>
                            <h1 className=' wrap text-[30px] leading-[27px] py-3 '>Automation helps maximize that ROI:</h1>
                            <div className='leading-[27px] wrap text-grayColor py-3'>
                                <ul>
                                    <li>Identifies who needs attention the most and what actions to take</li>
                                    <li>Removes manual steps such as updating different systems, typing up the same email or scheduling follow-ups</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-between mb-[5vh] md:px-[10%] px-[2%]'>
                <div className='flex md:flex-row flex-col gap-5'>
                    <div>
                        <a href='/4-effective-customer-success-strategies-for-2019-2'>
                            <img height='87' width='87' src={CustomerStrategy} />
                        </a>
                    </div>
                    <div className='w-[10vw]'>
                        <h3 className='text-[12px]'>PREVIOUS ARTICLE</h3>
                        <p className='text-[14px] font-bold text-grayColor'>4 Effective Customer Success Strategies for 2019Related Pos</p>
                    </div>
                </div>
                <div className='flex md:flex-row flex-col gap-5'>
                    <div>
                        <a href='/how-to-quantify-customer-success'>
                            <img height='87' width='87' src={DataControlImg} />
                        </a>
                    </div>
                    <div className='w-[10vw]'>
                        <h3 className='text-[12px]'>NEXT ARTICLE</h3>
                        <p className='text-[14px] font-bold text-grayColor'>Controlling Your Customer Success Data in the Cloud</p>
                    </div>
                </div>
            </div>
            <div className='w-full bg-ghostWhite mb-[2%] md:px-[10%] px-[2%] py-[2%]'>
                <h2 className={`text-black  font-bold text-[24px] text-center`}>Related Posts</h2>
                <div className='pt-[40px]'>
                    <div className='flex items-center justify-center '>
                        <div className={`md:grid md:grid-cols-3  md:grid-flow-row grid-cols-1 md:leading-[50px] grid gap-y-10 gap-x-5`}>
                            <a href='/how-to-quantify-customer-success'>
                                <img src={QuantifyImg} />
                                <h2 className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>How to Quantify Customer Success</h2>
                                <p className='text-grayColor'>{'December 2, 2020'}</p>
                            </a>
                            <a href='/who-should-own-customer-success'>
                                <img src={Ownership} />
                                <h2 className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>Who should own Customer Success</h2>
                                <p className='text-grayColor'>{'September 10, 2020'}</p>
                            </a>
                            <a href='/controlling-your-customer-success-data-in-the-cloud'>
                                <img src={DataControlImg} />
                                <h2 className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>Controlling Your Customer Success Data in the Cloud</h2>
                                <p className='text-grayColor'>{'July 20, 2020'}</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <section className='font-nunito-sans  pb-[5vh] md:px-[10%] px-[2%]'>
                <div className=' bg-yellow  rounded-[2vh] md:flex-row md:items-center md:justify-center md:flex flex-col pt-8 px-8'>
                    <div>
                        <h3 className='lg:text-3xl md:text-xl font-bold pb-[5vh]'>Want to know how we can help you understand your customers better?</h3>
                        <div className='flex md:flex-row flex-col gap-5'>
                            <input
                                className=" md:w-[25vw] border border-solid-gray p-3 border-[1px] rounded-[15px] hover:border-blue-500 focus:border-blue-500 focus:outline-none focus-within:border-blue-500"
                                placeholder="Your Email Address"
                            />
                            <button className=' lg:w-[13vw] sm:w-[20vw]  mt-[2vh] w-[30vw] bg-black text-white h-[6vh] text-sm md:text-md xl:text-xl rounded-[1vh]'>Request Demo</button>
                        </div>
                    </div>
                    <img src={Hand} />
                </div>
            </section>
        </div>
    )
}
