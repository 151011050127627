import React from 'react'
import RightArrow from '../../../assets/right-arrow.png'

export default function TwoGridCol({listData}) {
    return (
        <>
            <section>
                {listData.map((data,datakey)=>{
                return(
                <div key={datakey} className='font-nunito-sans flex items-center justify-center relative md:px-[10%] px-[2%]'>                   
                        <div className={`grid  lg:grid-row-2 lg:grid-flow-col py-10 gap-4`}>
                            <div className={`${data.Order.one} `}>
                                <h3 className='text-[31px] font-bold pb-[10px]'>{data.Title}</h3>
                                <p className='text-[21px] font-thin text-grayColor  mb-[15px]'>{data.Description}</p>
                                <ul>
                                    {data?.List.map((listData,listIndex)=>{
                                    return(
                                    <li key={listIndex} className='flex'>
                                        <img src={RightArrow} className='pr-[30px] pb-[8px] mb-[15px]' />
                                        <h4 className='text-[18px] font-semibold'>{listData}</h4>
                                    </li> 
                                    )
                                    })}                                  
                                </ul>
                            </div>
                            <div className={`${data.Order.two} `}>
                                <img src={data.Image} />
                            </div>
                        </div>
                    </div>
                )})}
            </section>
        </>
    )
}
