import React, {useState} from 'react'
import PricingArc from '../../../assets/Arc.png'
import PricingHand from '../../../assets/pricing-hand-1.png'
import Tick from '../../../assets/tick.svg'
import RequestDemo from '../../CommonFunction/PopHover/RequestDemo'

export default function Pricing() {
    const [isPopupLetsTalkVisible, setPopupLetsTalkVisible] = useState(false);

    const handleLetsTalkClick = () => {
        setPopupLetsTalkVisible(true);
    };
  
    const handleLetsTalkClosePopup = () => {
        setPopupLetsTalkVisible(false);
    };
    const [isPopupVisible, setPopupVisible] = useState(false);

    const handleRequestDemoClick = () => {
      setPopupVisible(true);
    };
  
    const handleClosePopup = () => {
      setPopupVisible(false);
    };
    return (
        <div className='relative w-full '>
            <div className='absolute w-full'>
            <div className='h-[70vh] bg-ghostWhite '>                
            </div>
            <img className=' w-full fill-ghostWhite' src={PricingArc} />
            </div>
            <section className=' relative flex justify-center p-10'>
                    <div className='w-[50%] text-center'>
                        <h3 className='lg:text-5xl px-[10vh] text-2xl font-bold p-[3vh]'>Pricing doesn’t have to be complicated</h3>
                        <h6 className='lg:text-xl text-md font-bold '>Start simple and scale as you grow!</h6>
                    </div>
                </section>
            <section className=' relative flex items-center justify-center '>
                <div className='lg:w-[75%]'>
                <div className={` bg-white grid lg:grid-row-2 lg:grid-flow-col grid-col w-full `}>
                    <div className='p-20 relative '>
                        <h3 className='lg:text-3xl text-2xl font-bold '>Fully Featured</h3>
                        <ul className='p-5'>
                            <div className='flex gap-5 text-xl py-2'><img src={Tick}/><li>Customer 360</li></div>
                            <div className='flex gap-5 text-xl py-1'><img src={Tick}/><li>Unlimited Integrations</li></div>
                            <div className='flex gap-5 text-xl py-2'><img src={Tick}/><li>External Process Automation</li></div>
                            <div className='flex gap-5 text-xl py-1'><img src={Tick}/><li>Key Performance Indicators</li></div>
                            <div className='flex gap-5 text-xl py-2'><img src={Tick}/><li>UI Builders</li></div>
                            <div className='flex gap-5 text-xl py-1'><img src={Tick}/><li>Custom Data Attributes</li></div>
                            <div className='flex gap-5 text-xl py-2'><img src={Tick}/><li>Playbooks</li></div>
                            <div className='flex gap-5 text-xl py-1'><img src={Tick}/><li>Success Plans</li></div>
                            <div className='flex gap-5 text-xl py-2'><img src={Tick}/><li>Email and Calendar Integrations</li></div>
                            <div className='flex gap-5 text-xl py-1'><img src={Tick}/><li>Triggered Alerts</li></div>
                            <div className='flex gap-5 text-xl py-2'><img src={Tick}/><li>Fully Customizable</li></div>
                            <div className='flex gap-5 text-xl py-1 '><img src={Tick}/><li>Unlimited Read-only users </li></div>
                        </ul>                      
                    </div>                    
                    <div className='flex items-center justify-center'>
                    <div className='bg-white'>
                    <div className='text-center px-20'>
                        <h6 className='text-[61px] font-bold'>$ 99 </h6>
                        <h4 className='text-xl font-semibold'>per user, per month</h4>
                        <h1>*for minimum of 5 users</h1>
                        <div className='mt-4 text-center '>
                            <button onClick={handleLetsTalkClick} className='my-2 mx-0 inline-block text-white rounded-md px-3 py-2 text-base bg-blueColor shadow-sm'>Let's talk</button>
                        </div>                     
                        {isPopupLetsTalkVisible && <RequestDemo onClose={handleLetsTalkClosePopup} />}
                    </div>
                    <div className='w-full text-center  border border-t-gray border-b-gray border-l-white border-r-white p-5 mt-[10px]'>
                            <h2 className='text-xl'><span className='font-semibold'>+ $89</span> per user after 5 users</h2>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

            </section>
            <section className='flex items-center justify-center mb-[5%]  '>
                        <div className={` bg-aquamarine  md:w-[150vh] lg:h-[48vh] md:h-[40vh] rounded-[2vh] md:flex-row md:items-center md:justify-center flex flex-col pt-8 `}>
                            <div className='p-10'>
                                <h3 className='lg:text-3xl text-2xl font-bold md:pt-15 md:pl-10 md:pb-10'>Want to know how we can help you understand your customers better?</h3>
                                <button onClick={handleRequestDemoClick} className='md:ml-[6vh] lg:w-[13vw] sm:w-[20vw]  mt-[2vh] w-[30vw] bg-black text-white h-[6vh] text-sm md:text-md xl:text-xl rounded-[1vh]'>Request Demo</button>
                            </div>
                            <img  src={PricingHand} />
                        </div>
                    </section>    
                    {isPopupVisible && <RequestDemo onClose={handleClosePopup} />} 
        </div>
    )
}
