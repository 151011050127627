import React from 'react'
import QuantifyImg from '../../../../assets/quantify_cs.svg'
import Ownership from '../../../../assets/ownership_cs.svg'
import CustomerStrategy from '../../../../assets/strategies_2019.svg'
import DataControlImg from '../../../../assets/data_control.svg'
import RoiCsImg from '../../../../assets/roi_cs.svg'
import Hand from '../../../../assets/hand.png'

export default function Strategy() {
    return (
        <div className='w-full' >
            <div className='mb-[5vh]'>
                <div className='mb-[5vh]'>
                    <div className='w-full absolute  '>
                        <img className='w-full xl:h-[calc(100vh-15vh)] object-cover' src={CustomerStrategy} />
                    </div>
                    <section className='relative flex flex-col items-center justify-end xl:h-[calc(100vh-15vh)]  bg-black bg-opacity-30  mb  text-white'>
                        <a href='/blog' className='pb-[2%] text-center'>Back to Blogs</a>
                        <h1 className='text-4xl font-bold pb-[2%] text-center'>4 Effective Customer Success Strategies for 2019</h1>
                        <p className='pb-[5%] text-center'>Balaji / August 4, 2019</p>
                    </section>
                </div>
                <div className='w-full wrap text-[18px] '>
                    <div className='flex flex-col items-center justify-center '>
                        <div className='md:px-[20%] px-[5%]'>
                            <div className=' wrap  leading-[27px] py-3 text-grayColor'>For any company, determining customer success involves a variety of factors—not just product/service quality. In order to stand out in today’s evolving marketplace, businesses must recognize the importance of customer experience. A satisfied customer is a loyal customer. So, how can your business capitalize? It all comes down to incorporating effective customer success strategies. With the right methods in place, you can look forward to an increase in profits and growth in audience. Here are four of the most effective customer success strategies to adopt in 2019.</div>
                            <h1 className=' wrap  leading-[27px] py-1 '>1. Make onboarding easy for new customers</h1>

                            <p className=' wrap leading-[27px] py-3 text-grayColor'>Once your sales team has turned a prospect into a customer, you must begin the onboarding experience. Onboarding involves welcoming a new customer into the fold, guiding them toward successful and effective use of your company’s product or service. This stage of customer success is crucial. If your customer is not thoroughly acquainted from the beginning, it will be that much easier for them to feel disheartened and back out.</p>
                            <p className=' wrap leading-[27px] py-3 text-grayColor'>Onboarding should be, above all else, a friendly and encouraging step for your customer. Critically, it must also be seamless for everyone involved. The more you personalize and simplify the onboarding experience for your customer, the better. Assess their expectations, remind them of their goals upfront, and supply them with the tools and resources required. After all, when a new customer obtains immediate value from your product, they are more likely to continue using it, refer new prospects to you and most importantly, become a reference.</p>

                            <h1 className=' wrap  leading-[27px] py-1 '>2. Increase response turnaround on customer support</h1>

                            <p className=' wrap leading-[27px] py-3 text-grayColor'>Response time is another metric that B2B companies must continually improve. When customers reach out with issues, it’s best to diffuse the situation quickly and provide immediate assistance. In order to ensure that customers are responded to in a timely manner, management should create specialized roles for monitoring consumer feedback. Believe it or not, many companies do not place great value on efficient response turnaround. In fact, some companies do not answer customer issues at all and customers recognize this. Therefore, businesses that do operate with an effective and responsive support team in place tend to have increased customer satisfaction.</p>
                            <p className=' wrap leading-[27px] py-3 text-grayColor'>Again, this incredibly common pitfall is easy to avoid. Respond to customer support emails quickly and courteously. This works even if the responding employee does not have the product knowledge or sufficient expertise to assist. Simply by informing the customer that their request has been processed and help is on the way means everything.</p>

                            <h1 className=' wrap  leading-[27px] py-1 '>3. Be proactive—recognize issues before your customers do</h1>

                            <p className=' wrap leading-[27px] py-3 text-grayColor'>Businesses with an effective and efficient customer support team in place are typically more successful than those without. However, in addition to customer support, it is imperative that your customer success team is proactively anticipating potential issues and addressing them before your clients surface them. Recognizing and fixing problems ahead of time ensures that your customers feel valued and important.</p>
                            <p className=' wrap leading-[27px] py-3 text-grayColor'>Here’s where Customer Success Automation with augmented intelligence (AI) can make a difference. Based on many different triggers, AI can proactively help you predict customer issues and resolve them in a timely manner. Always monitor product efficiency—save yourself, and customers, the headache of becoming as predictive as possible.</p>

                            <h1 className=' wrap  leading-[27px] py-1 '>4. Turn Customers into Advocates</h1>

                            <p className=' wrap leading-[27px] py-3 text-grayColor'>As a business, the ability to form meaningful connections with your customers (in little time) ensures greater reach and faster turnaround. Once these connections are made, it becomes easier for companies to communicate directly with their customers in an accessible, informal manner. Your customers should provide you with direct feedback on your product/services, and if they don’t…ask!</p>
                            <p className=' wrap leading-[27px] py-3 text-grayColor'>Implementing a customer feedback process isn’t complicated and can help in many ways. NPS scores are critical for customer success teams. Implementing a process to collect this information informs you on whether that customer is likely to recommend you to others.</p>
                            <p className=' wrap leading-[27px] py-3 text-grayColor'>There you have it! Four key strategies that your business can incorporate in order to boost customer success. By adhering to, and effectively executing these methods of customer engagement, you can expect an increase in revenue and a happier, long-lasting customer base.</p>

                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-between mb-[5vh] md:px-[10%] px-[2%]'>
                <div className='flex md:flex-row flex-col gap-5'>
                    <div>
                        <h1>{''}</h1>
                    </div>
                </div>
                <div className='flex md:flex-row flex-col gap-5'>
                    <div>
                        <a href='/4-effective-customer-success-strategies-for-2019-2'>
                            <img height='87' width='87' src={RoiCsImg} />
                        </a>
                    </div>
                    <div className='w-[10vw]'>
                        <h3 className='text-[12px]'>NEXT ARTICLE</h3>
                        <p className='text-[14px] font-bold text-grayColor'>Getting Full ROI from your Customer Success Investment</p>
                    </div>
                </div>
            </div>
            <div className='w-full bg-ghostWhite mb-[2%] md:px-[10%] px-[2%] py-[2%]'>
                <h2 className={`text-black  font-bold text-[24px] text-center`}>Related Posts</h2>
                <div className='pt-[40px]'>
                    <div className='flex items-center justify-center '>
                        <div className={`md:grid md:grid-cols-3  md:grid-flow-row grid-cols-1 md:leading-[50px] grid gap-y-10 gap-x-5`}>
                            <a href='/how-to-quantify-customer-success'>
                                <img src={QuantifyImg} />
                                <h2 className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>How to Quantify Customer Success</h2>
                                <p className='text-grayColor'>{'December 2, 2020'}</p>
                            </a>
                            <a href='/who-should-own-customer-success'>
                                <img src={Ownership} />
                                <h2 className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>Who should own Customer Success</h2>
                                <p className='text-grayColor'>{'September 10, 2020'}</p>
                            </a>
                            <a href='/controlling-your-customer-success-data-in-the-cloud'>
                                <img src={DataControlImg} />
                                <h2 className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>Controlling Your Customer Success Data in the Cloud</h2>
                                <p className='text-grayColor'>{'July 20, 2020'}</p>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
            <section className='font-nunito-sans  pb-[5vh] md:px-[10%] px-[2%]'>
                <div className=' bg-yellow  rounded-[2vh] md:flex-row md:items-center md:justify-center md:flex flex-col pt-8 px-8'>
                    <div>
                        <h3 className='lg:text-3xl md:text-xl font-bold pb-[5vh]'>Want to know how we can help you understand your customers better?</h3>
                        <div className='flex md:flex-row flex-col gap-5'>
                            <input
                                className=" md:w-[25vw] border border-solid-gray p-3 border-[1px] rounded-[15px] hover:border-blue-500 focus:border-blue-500 focus:outline-none focus-within:border-blue-500"
                                placeholder="Your Email Address"
                            />
                            <button className=' lg:w-[13vw] sm:w-[20vw]  mt-[2vh] w-[30vw] bg-black text-white h-[6vh] text-sm md:text-md xl:text-xl rounded-[1vh]'>Request Demo</button>
                        </div>
                    </div>
                    <img src={Hand} />
                </div>
            </section>
        </div>
    )
}
